import Moment from "moment";

/**
 * Please, do not use this class with a large amount of data, it will consume a lot of memory
 * we setup this method to only be used with a small amount of data, to prevent backend maintainance
 * be careful with the amount of data that you are exporting;
 * 
 * 
 * -------------------------- THE XLSX LIBRARY IS NOT INCLUDED IN THIS SNIPPET --------------------------
 * ON THIS PROJECT WE IMPORT THE FILE ON INDEX.HTML
 * <script src="https://cdn.sheetjs.com/xlsx-0.19.3/package/dist/xlsx.full.min.js"></script>
 * -----------------------------------------------------------------------------------------------------
 * 
 * Any doubts, please contact Claudio or Bruno
*/


/** 
 * Class to export data to an excel file
 * 
 */
class DataExporter {

    /**
     * 
     * @param {Function} serviceToCall the service that will be called to fetch the data
     * @param {Object} optionsToService options that the service needs to fetch the data 
     * @param {Int} itemsPerPage the number of items that the service returns per page
     * @param {Int} totalPages the total of pages that we need to fetch
     * @param {Object} columnsToExport an object with the columns that will be exported, the key is the name of the column in the exported file and the value is the name of the column in the data 
     */
    constructor({ serviceToCall, optionsToService, itemsPerPage, totalPages, columnsToExport, fileName = 'exportedData' } = {}) {
        if (!serviceToCall || !optionsToService || !itemsPerPage || !totalPages || !columnsToExport) {
            throw new Error('All parameters are required');
        }
        this.serviceToCall = serviceToCall;
        this.optionsToService = optionsToService;
        this.itemsPerPage = itemsPerPage;
        this.totalPages = totalPages;
        this.columnsToExport = columnsToExport;
        this.fileName = fileName;
    }

    async exportData() {
        try {
            const data = await this.fetchData();
            this.exportToExcel(data);
            return true;
        } catch (error) {
            console.error('Error exporting data:', error);
            return false;
        }
    }

    async fetchData() {
        const accumulatedData = [];
        let currentPage = 1;

        while (currentPage <= this.totalPages) {
            this.optionsToService.currentPage = currentPage;
            this.optionsToService.perPage = this.itemsPerPage;
            const pageData = await this.serviceToCall(this.optionsToService);
            const processedData = this.processPageData(pageData);
            accumulatedData.push(...processedData);
            if (pageData.length < this.itemsPerPage) {
                break;
            }
            currentPage++;
        }

        return accumulatedData;
    }

    getNestedValue(obj, path) {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    processPageData(pageData) {
        return pageData.map(item => {
            const orderedItem = {};
            Object.keys(this.columnsToExport).forEach(columnKey => {
                orderedItem[columnKey] = this.getNestedValue(item, this.columnsToExport[columnKey]);
            });
            return orderedItem;
        });
    }

    exportToExcel(data) {
        const ws = XLSX.utils.json_to_sheet(data, { header: Object.keys(this.columnsToExport) });
        const wb = XLSX.utils.book_new();
        const wscols = Object.keys(this.columnsToExport).map(columnName => ({ wch: columnName.length + 15 }));
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const formattedDate = Moment().format('DD-MM-YYYY HH-mm-ss');
        const fileName = `${this.fileName}_${formattedDate}.xlsx`;
        XLSX.writeFile(wb, fileName);
    }
}


export default DataExporter;